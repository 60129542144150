import React from 'react';
import '../css/general.css';

const Header = () => {
    return (
        <div className="headerStyle">
            braelyn.dev<img className="headerImage" src={require('../image/b-circular.png')}></img>
        </div>
    );
};

export default Header;
