import React from 'react';
import { useHistory } from 'react-router-dom';

const SidebarButton = ({ text, destination }) => {
    let history = useHistory();

    return (
        <div
            className="sidebarButtonStyle"
            onClick={() => {
                history.push(destination);
            }}
        >
            {text}
        </div>
    );
};

export default SidebarButton;
