import React, { Component } from 'react';
import soundfile from './poem1.mp3';
import ReactAudioPlayer from 'react-audio-player';

class Poems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: 0,
        };
    }

    wordSelect = number => {
        switch (number) {
            case 1:
                // Twinkle
                return this.state.time >= 0.2 ? 'wordSelect' : null;
            case 2:
                // Twinkle
                return this.state.time >= 1 ? 'wordSelect' : null;
            case 3:
                // Christmas
                return this.state.time >= 2.2 ? 'wordSelect' : null;
            case 4:
                // Lights
                return this.state.time >= 3.4 ? 'wordSelect' : null;
            case 5:
                // Lighting
                return this.state.time >= 4.5 ? 'wordSelect' : null;
            case 6:
                // Up
                return this.state.time >= 5.5 ? 'wordSelect' : null;
            case 7:
                // December
                return this.state.time >= 6.4 ? 'wordSelect' : null;
            case 8:
                // Nights
                return this.state.time >= 7.7 ? 'wordSelect' : null;
            case 9:
                // Red
                return this.state.time >= 8.8 ? 'wordSelect' : null;
            case 10:
                // And
                return this.state.time >= 9.5 ? 'wordSelect' : null;
            case 11:
                // Yellow
                return this.state.time >= 10 ? 'wordSelect' : null;
            case 12:
                // Green
                return this.state.time >= 10.8 ? 'wordSelect' : null;
            case 13:
                // And
                return this.state.time >= 11.4 ? 'wordSelect' : null;
            case 14:
                // Blue
                return this.state.time >= 12 ? 'wordSelect' : null;
            case 15:
                // Making
                return this.state.time >= 13.1 ? 'wordSelect' : null;
            case 16:
                // Such
                return this.state.time >= 13.7 ? 'wordSelect' : null;
            case 17:
                // A
                return this.state.time >= 14 ? 'wordSelect' : null;
            case 18:
                // Lovely
                return this.state.time >= 14.8 ? 'wordSelect' : null;
            case 19:
                // View
                return this.state.time >= 15.6 ? 'wordSelect' : null;
            case 20:
                // Twinkle
                return this.state.time >= 16.6 ? 'wordSelect' : null;
            case 21:
                // Twinkle
                return this.state.time >= 17.5 ? 'wordSelect' : null;
            case 22:
                // Christmas
                return this.state.time >= 18.5 ? 'wordSelect' : null;
            case 23:
                // Lights
                return this.state.time >= 19.6 ? 'wordSelect' : null;
            case 24:
                // Lighting
                return this.state.time >= 20.6 ? 'wordSelect' : null;
            case 25:
                // Up
                return this.state.time >= 21.6 ? 'wordSelect' : null;
            case 26:
                // December
                return this.state.time >= 22.3 ? 'wordSelect' : null;
            case 27:
                // Nights
                return this.state.time >= 23.4 ? 'wordSelect1' : null;
        }
    };

    listened = () => {
        this.setState({ time: this.player.audioEl.currentTime });
        console.log(this.state.time);
    };

    render() {
        return (
            <div className="poemStyle">
                <div className="poemBody">
                    <span className="poemTitle"> Twinkle, Twinkle, Christmas Lights </span>
                    <br />
                    <br />
                    <span className={this.wordSelect(1)}>Twinkle,</span>{' '}
                    <span className={this.wordSelect(2)}>twinkle,</span>
                    <br />
                    <span className={this.wordSelect(3)}>Christmas</span>{' '}
                    <span className={this.wordSelect(4)}>lights,</span>
                    <br />
                    <span className={this.wordSelect(5)}>Lighting</span>{' '}
                    <span className={this.wordSelect(6)}>up</span>{' '}
                    <span className={this.wordSelect(7)}>December</span>{' '}
                    <span className={this.wordSelect(8)}>nights.</span>
                    <br />
                    <span className={this.wordSelect(9)}>Red</span>{' '}
                    <span className={this.wordSelect(10)}>and</span>{' '}
                    <span className={this.wordSelect(11)}>yellow,</span>
                    <br />
                    <span className={this.wordSelect(12)}>Green</span>{' '}
                    <span className={this.wordSelect(13)}>and</span>{' '}
                    <span className={this.wordSelect(14)}>blue,</span>
                    <br />
                    <span className={this.wordSelect(15)}>Making</span>{' '}
                    <span className={this.wordSelect(16)}>such</span>{' '}
                    <span className={this.wordSelect(17)}>a</span>{' '}
                    <span className={this.wordSelect(18)}>lovely</span>{' '}
                    <span className={this.wordSelect(19)}>view.</span>
                    <br />
                    <span className={this.wordSelect(20)}>Twinkle,</span>{' '}
                    <span className={this.wordSelect(21)}>twinkle,</span>
                    <br />
                    <span className={this.wordSelect(22)}>Christmas</span>{' '}
                    <span className={this.wordSelect(23)}>lights,</span>
                    <br />
                    <span className={this.wordSelect(24)}>Lighting</span>{' '}
                    <span className={this.wordSelect(25)}>up</span>{' '}
                    <span className={this.wordSelect(26)}>December</span>{' '}
                    <span className={this.wordSelect(27)}>nights.</span>
                </div>
                <div>
                    <ReactAudioPlayer
                        className="audio"
                        ref={element => {
                            this.player = element;
                        }}
                        src={soundfile}
                        listenInterval={100}
                        onListen={this.listened}
                        controls
                    />
                </div>
            </div>
        );
    }
}

export default Poems;

// playStatus={Sound.status.PLAYING}
// onLoading={this.handleSongLoading}
// onPlaying={this.handleSongPlaying}
// onFinishedPlaying={this.handleSongFinishedPlaying}
