import React from 'react';

const Music = () => {
    return (
        <div className="musicStyle">
            This is my favourite Christmas song!
            <div className="container">
                <iframe
                    className="video"
                    src="https://www.youtube.com/embed/3CWJNqyub3o"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                />
            </div>
        </div>
    );
};

export default Music;
