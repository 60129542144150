import React from 'react';
import Carousel from 'nuka-carousel';
const pic1 = require('../image/picture1.jpg');
const pic2 = require('../image/picture2.jpg');
const pic3 = require('../image/picture3.jpg');
const pic4 = require('../image/picture4.jpg');
const pic5 = require('../image/picture5.jpg');
const pic6 = require('../image/picture6.jpg');
const pic7 = require('../image/picture7.jpg');
const pic8 = require('../image/picture8.jpg');
const pic9 = require('../image/picture9.jpg');
const pic10 = require('../image/picture10.jpg');

const Art = () => {
    return (
        <div className="pictureStyle">
            <div className="picture">
                <Carousel cellAlign="center" cellSpacing={100} slideWidth={0.25}>
                    <img src={pic1} />
                    <img src={pic2} />
                    <img src={pic3} />
                    <img src={pic4} />
                    <img src={pic5} />
                    <img src={pic6} />
                    <img src={pic7} />
                    <img src={pic8} />
                    <img src={pic9} />
                    <img src={pic10} />
                </Carousel>
                <br></br>
                My favourite art!
            </div>
        </div>
    );
};

export default Art;
