import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Landing from './Landing';
import Music from './Music';
import Poems from './Poems';
import Art from './Art';

const App = () => {
    return (
        <div className="mainStyle">
            <Router>
                <Route
                    render={({ location }) => (
                        <div style={{ height: '100vh' }}>
                            <div>
                                <Route exact path="/" render={() => <Redirect to="/landing" />} />
                            </div>
                            <div>
                                <Header />
                                <Sidebar />
                                <Switch location={location}>
                                    <Route exact path="/landing" component={Landing} />
                                    <Route exact path="/landing/music" component={Music} />
                                    <Route exact path="/landing/poems" component={Poems} />
                                    <Route exact path="/landing/art" component={Art} />
                                    <Route render={() => <div>Not Found</div>} />
                                </Switch>
                            </div>
                        </div>
                    )}
                />
            </Router>
        </div>
    );
};

export default App;
