import React from 'react';
import SidebarButton from './SidebarButton';

const Sidebar = () => {
    return (
        <div className="sidebarStyle">
            <SidebarButton destination="/landing" text="Main" />
            <SidebarButton destination="/landing/music" text="Music" />
            <SidebarButton destination="/landing/poems" text="Poems" />
            <SidebarButton destination="/landing/art" text="Art" />
        </div>
    );
};

export default Sidebar;
