import React from 'react';

const Landing = () => {
    return (
        <div className="landingStyle">
            <div className="landingTextStyle">
                Hi my name is Braelyn! <br></br>
                <br></br>
                My dad helped me build this website during Christmas.<br></br>
                <br></br>I hope you like it!
            </div>
        </div>
    );
};

export default Landing;
